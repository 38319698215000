/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/system';
import { TextField } from '@mui/material';
import { useState } from 'react';
import { perfilIcon } from './RenderUtils';
import { dynamicSort } from './utils';

function PerfilInstituicaoSelecionForm({ perfil, setInstituicao }) {
  const [search, setSearch] = useState('');

  const valuesFilter = perfil.instituicoes.filter((i) =>
    i.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <Box sx={{ color: 'secondary.main' }}>
        <strong>Perfil {perfil.name}</strong>
      </Box>
      <p>Selecione em qual instituição deseja logar</p>
      <TextField
        focused
        autoFocus
        size="small"
        name="filter"
        aria-label="Procura"
        placeholder="procura"
        onChange={(event) => setSearch(event.target.value)}
      />
      <List
        sx={{
          width: '100%',
          maxWidth: '100%',
          bgcolor: 'background.paper'
        }}
      >
        {valuesFilter.sort(dynamicSort('name')).map((i, key) => (
          <ListItemButton key={key} onClick={() => setInstituicao(i)}>
            <ListItemAvatar>
              <Avatar>{perfilIcon(i)}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={i.name}
              secondary={i.additional}
              primaryTypographyProps={{
                fontSize: 15,
                fontWeight: 'medium',
                lineHeight: '20px',
                mb: '2px'
              }}
              secondaryTypographyProps={{
                fontSize: 12
              }}
            />
          </ListItemButton>
        ))}
      </List>
    </>
  );
}
export default PerfilInstituicaoSelecionForm;
