import './App.css';

import { useSelector } from 'react-redux';
import MainLoginPanels from './app/MainLoginPanel';
import withAppProviders from './withAppProviders';
import FooterPage from './app/FooterPage';

function App() {
  const apiProperties = useSelector((state) => state.apiProperties);
  return (
    <>
      <header>
        <img
          src="images/portal_unimed-09.png"
          alt="Upper"
          className="justify-self-center"
        />
      </header>
      <div className="container-fluid grid grid-cols-1 gap-20 content-center">
        <img
          src="images/logo-unimed.png"
          className="mt-5 justify-self-center"
          alt="Logo"
        />
        <div className="max-w-sm justify-self-center">
          <img src="images/portal_unimed-10.png" className="mt-5" alt="Logo" />
          <MainLoginPanels />
        </div>
      </div>
      <div id="recatchaContainer" />
      <footer>
        <FooterPage disclaimer={apiProperties?.disclaimer} />
      </footer>
    </>
  );
}

export default withAppProviders(App)();
