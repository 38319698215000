import * as React from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import store from './store/index';
import jwtService from './services/jwtService';

import { setApiProperties } from './reducers/apiPropertiesSlice';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/json';

const AppContextStore = (props) => {
  console.log('API_URL', process.env.REACT_APP_API_URL);
  const dispatch = useDispatch();
  const apiProperties = useSelector((state) => state.apiProperties);
  // const navigate = useNavigate();

  React.useEffect(() => {
    jwtService
      .apiVersion()
      .then((version) => dispatch(setApiProperties(version)))
      .catch((err) => {
        if (err?.request?.responseURL) {
          window.location.replace(err?.request?.responseURL);
        } else {
          console.error(err);
        }
      });
  }, [jwtService]);

  return props.children;
};

function AppContext(props) {
  return (
    <Provider store={store}>
      <AppContextStore {...props} />
    </Provider>
  );
}

export default AppContext;
