/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/prefer-default-export
export function dynamicSort(property) {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    if (!a || !b || !a[property] || !b[property]) {
      return 0;
    }
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}
