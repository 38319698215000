/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import * as React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import WelcomePage from './WelcomePage';

import LoginForm from './LoginForm';
import PerfilForm from './PerfilForm';
import loadash from '../@lodash';
import PerfilInstituicaoSelecionForm from './PerfilInstituicaoSelecionForm';
import jwtServiceConfig from '../services/jwtServiceConfig';
import PostForm from './PostForm';
/* 
      jwtService
        .authenticate(user, p)
        .then((response) => console.log('authenticate success', response))
        .catch((err) => console.error('authenticate error', err));
        */

/* eslint-disable react/display-name */
export default function () {
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState();
  const [perfil, setPerfil] = React.useState({});
  const [instituicao, setInstituicao] = React.useState({});
  const [fornecedor, setFornecedor] = React.useState({});
  const [empresa, setEmpresa] = React.useState({});
  const apiProperties = useSelector((state) => state.apiProperties);
  const useRecaptcha =
    apiProperties?.captchaKey && apiProperties?.captchaKey !== 'bypass';

  React.useEffect(() => {
    console.log('Logged user: ', user);
  }, [user]);

  React.useEffect(() => {
    console.log('Logged perfil: ', perfil);
  }, [perfil]);

  React.useEffect(() => {
    if (
      perfil.id &&
      (perfil.clazz ===
        'br.com.unimed.sae.negocio.modelo.perfil.PerfilOperadora' ||
        perfil.clazz ===
          'br.com.unimed.sae.negocio.modelo.perfil.PerfilSistema' ||
        perfil.clazz ===
          'br.com.unimed.sae.negocio.modelo.perfil.PerfilSuporte')
    ) {
      setLoading(true);
    }
    if (
      perfil.id &&
      perfil.clazz ===
        'br.com.unimed.sae.negocio.modelo.perfil.PerfilFornecedor' &&
      loadash.size(perfil.fornecedores) === 1
    ) {
      setFornecedor(perfil.fornecedores[0]);
      setLoading(true);
    }
    if (
      instituicao.id &&
      (perfil.clazz ===
        'br.com.unimed.sae.negocio.modelo.perfil.PerfilPrestador' ||
        perfil.clazz ===
          'br.com.unimed.sae.negocio.modelo.perfil.PerfilConsultorio' ||
        perfil.clazz ===
          'br.com.unimed.sae.negocio.modelo.perfil.PerfilAmbulatorio') &&
      loadash.size(perfil.instituicoes) === 1
    ) {
      setInstituicao(perfil.instituicoes[0]);
      setLoading(true);
    }
  }, [perfil]);

  const handleUser = (newUser) => {
    setUser(newUser);
    if (loadash.size(newUser.perfis) === 1) {
      setPerfil(newUser.perfis[0]);
    }
  };

  React.useEffect(() => {
    if (
      instituicao.id &&
      (perfil.clazz ===
        'br.com.unimed.sae.negocio.modelo.perfil.PerfilPrestador' ||
        perfil.clazz ===
          'br.com.unimed.sae.negocio.modelo.perfil.PerfilConsultorio' ||
        perfil.clazz ===
          'br.com.unimed.sae.negocio.modelo.perfil.PerfilAmbulatorio')
    ) {
      setLoading(true);
    }
  }, [instituicao]);

  React.useEffect(() => {
    if (
      empresa.id &&
      perfil.clazz === 'br.com.unimed.sae.negocio.modelo.perfil.PerfilEmpresa'
    ) {
      setLoading(true);
    }
  }, [empresa]);

  React.useEffect(() => {
    if (loading) {
      document.getElementById('postForm').submit();
    }
  }, [loading]);

  console.log('apiProperties', apiProperties);
  return (
    <>
      <PostForm
        action={`${process.env.REACT_APP_API_URL}${jwtServiceConfig.login}`}
        instituicao={instituicao}
        empresa={empresa}
        fornecedor={fornecedor}
        user={user}
        perfil={perfil}
      />
      {(loading || !apiProperties) && (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: `${-32 / 2}px`,
            marginLeft: `${-32 / 2}px`
          }}
          size={32}
          className="flex justify-center items-center h-screen"
        />
      )}

      <div className="d-flex flex-col">
        {apiProperties?.error && (
          <Alert severity="error">
            {apiProperties?.error?.response?.data?.errorMessage ||
              apiProperties?.error?.message}
          </Alert>
        )}

        <div className="flex left">
          {!user && <WelcomePage />}
          <div className="flex-1 p-6">
            {!user && apiProperties?.code && (
              <LoginForm handleUser={handleUser} useRecaptcha={useRecaptcha} />
            )}
            {user && apiProperties?.code && (
              <div>
                <p>
                  <Box sx={{ color: 'primary.main' }}>
                    <strong>{`Bem vindo, ${user.name}`}</strong>
                  </Box>
                </p>
                {!perfil.id && user.perfis && (
                  <PerfilForm user={user} setPerfil={setPerfil} />
                )}

                {(perfil.clazz ===
                  'br.com.unimed.sae.negocio.modelo.perfil.PerfilAmbulatorio' ||
                  perfil.clazz ===
                    'br.com.unimed.sae.negocio.modelo.perfil.PerfilPrestador' ||
                  perfil.clazz ===
                    'br.com.unimed.sae.negocio.modelo.perfil.PerfilConsultorio') &&
                  perfil.instituicoes && (
                    <PerfilInstituicaoSelecionForm
                      perfil={perfil}
                      setInstituicao={setInstituicao}
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
