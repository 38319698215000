import Box from '@mui/material/Box';

function WelcomePage() {
  return (
    <div className="p-6 text-left max-w-200">
      <p className="text-primary fs-4 justify-left">
        <Box sx={{ color: 'primary.main' }}>
          <strong>Bem vindo</strong>
        </Box>
      </p>
      <p className="text-secondary">
        <Box sx={{ color: 'secondary.main' }}>
          Ao ambiente virtual exclusivo que aproxima ainda mais a Unimed Porto
          Alegre de seus cooperados e prestadores.
        </Box>
      </p>
    </div>
  );
}

export default WelcomePage;
