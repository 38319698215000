/* eslint-disable class-methods-use-this */
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';

/* eslint-disable camelcase */

class JwtService {
  init() {
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          console.log('Error calling ', err);
          if (
            err.response &&
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
    } else {
      this.setSession(null);
    }
  };

  signInWithEmailAndPassword = (username, password, token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signIn, {
          username,
          password,
          token
        })
        .then((response) => {
          console.log('Chamada retornou ', response);
          if (response.data.errorMessage) {
            console.log('Erro identificado ', response.data.errorMessage);
            reject(response.data);
          } else if (response.data && response.data.token) {
            console.log('Token ', response.data.token);
            this.setSession(response.data.token);
            resolve(response.data.object);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.accessToken, {
          accessToken: this.getAccessToken()
        })
        .then((response) => {
          console.log('Logged info: ', response.data);
          if (response.data && response.data.token) {
            this.setSession(response.data.token);
            resolve(response.data.object);
          } else {
            this.logout();
            reject(new Error('Falhou ao efetuar login com token.'));
          }
        })
        .catch((error) => {
          this.logout();
          reject(error);
        });
    });
  };

  apiVersion = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(jwtServiceConfig.ping)
        .then((r) => {
          axios
            .get(jwtServiceConfig.apiVersion, {
              headers: {
                'Content-Type': 'application/json'
              }
            })
            .then((response) => {
              console.log('Api version: ', response);
              if (response.data.successMessage) {
                return resolve(response.data);
              }
              return reject(response);
            })
            .catch((error) => {
              return reject(error);
            });
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  setSession = (access_token) => {
    console.log(this);
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = (access_token) => {
    console.log(this);
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expirado');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    console.log(this);
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new JwtService();

export default instance;
