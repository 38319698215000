/* eslint-disable no-nested-ternary */
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import FactoryIcon from '@mui/icons-material/Factory';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AddHomeIcon from '@mui/icons-material/AddHome';
import BusinessIcon from '@mui/icons-material/Business';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

export const perfilIcon = (pp) =>
  pp?.clazz === 'br.com.unimed.sae.negocio.modelo.perfil.PerfilSuporte' ? (
    <SupportAgentIcon />
  ) : pp?.clazz === 'br.com.unimed.sae.negocio.modelo.perfil.PerfilSistema' ? (
    <SettingsSuggestIcon />
  ) : pp?.clazz ===
    'br.com.unimed.sae.negocio.modelo.perfil.PerfilPrestador' ? (
    <LocalHospitalIcon />
  ) : pp?.clazz ===
    'br.com.unimed.sae.negocio.modelo.perfil.PerfilFornecedor' ? (
    <FactoryIcon />
  ) : pp?.clazz ===
    'br.com.unimed.sae.negocio.modelo.perfil.PerfilAmbulatorio' ? (
    <CorporateFareIcon />
  ) : pp?.clazz ===
    'br.com.unimed.sae.negocio.modelo.perfil.PerfilOperadora' ? (
    <BusinessIcon />
  ) : pp?.clazz ===
    'br.com.unimed.sae.negocio.modelo.perfil.PerfilConsultorio' ? (
    <AddHomeIcon />
  ) : null;

export const nothing = () => {
  return null;
};
