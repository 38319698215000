import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import * as yup from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import jwtService from '../services/jwtService';

/* eslint-disable react/display-name */
function LoginForm({ handleUser, useRecaptcha }) {
  const [loading, setLoading] = React.useState(false);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Talvez colocar cookie de remember me
  const defaultValues = {
    username: '',
    password: ''
  };

  const schema = yup.object().shape({
    username: yup.string().required('Você deve informar o usuário'),
    password: yup.string().required('Informe a senha')
  });
  const { control, formState, handleSubmit, reset, setError, watch, setValue } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema)
    });
  const form = watch();

  const { isValid, dirtyFields, errors } = formState;

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(
    async (callback) => {
      if (!useRecaptcha) {
        if (callback) callback();
        return;
      }

      if (!executeRecaptcha) {
        console.log('Ainda nao disponivel');
        return;
      }

      const token = await executeRecaptcha('LoginForm');
      setValue('token', token);
      if (callback) callback();
    },
    [executeRecaptcha]
  );

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const submitForm = () => {
    handleSubmit(onSubmit, onError)();
  };
  const onSubmit = (data) => {
    console.log('Enviando dados', data);
    setLoading(true);
    jwtService
      .signInWithEmailAndPassword(data.username, data.password, data.token)
      .then((user) => {
        setLoading(false);
        handleUser(user);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.errorMessage) {
          setError('username', {
            type: 'custom',
            message: err?.response?.data?.errorMessage
          });
        }
      });
  };

  const onError = (err) => {
    console.error(err);
  };

  if (_.isEmpty(form)) {
    return 'Carregando';
  }
  return (
    <div className="">
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' }
        }}
        noValidate
        autoComplete="off"
      >
        <div className="flex flex-col justify-center w-full mt-32 space-y-16">
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={loading}
                label="Usuário"
                autoFocus
                type="name"
                error={!!errors.username}
                helperText={errors?.username?.message}
                variant="outlined"
                required
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={loading}
                label="Senha"
                type="password"
                error={!!errors.password}
                helperText={errors?.password?.message}
                variant="outlined"
                required
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />

          {/** disabled={loading || !isValid} */}
          <Button
            disabled={loading}
            onClick={() => {
              handleReCaptchaVerify(submitForm);
            }}
            variant="outlined"
          >
            Entrar
          </Button>
          {loading && (
            <CircularProgress
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: `${-24 / 2}px`,
                marginLeft: `${-24 / 2}px`
              }}
              size={24}
              className="flex justify-center items-center h-screen"
            />
          )}
        </div>
      </Box>
    </div>
  );
}

export default LoginForm;
