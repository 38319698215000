import { Box } from '@mui/system';

function FooterPage({ disclaimer }) {
  return (
    <p className="text-center">
      <Box sx={{ color: 'text.disabled' }}>{disclaimer}</Box>
    </p>
  );
}

export default FooterPage;
