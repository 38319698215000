/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/system';
import { perfilIcon } from './RenderUtils';
import { dynamicSort } from './utils';

function PerfilForm({ user, setPerfil }) {
  return (
    <>
      <p>
        <Box sx={{ color: 'secondary.main' }}>
          Selecione o perfil no qual deseja efetuar o login
        </Box>
      </p>
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper'
        }}
      >
        {(user.perfis || []).sort(dynamicSort('name')).map((p, key) => {
          return (
            p?.id && (
              <ListItemButton key={key} onClick={() => setPerfil(p)}>
                <ListItemAvatar>
                  <Avatar>{perfilIcon(p)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={p.name}
                  secondary={p.additional}
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 'medium',
                    lineHeight: '20px',
                    mb: '2px'
                  }}
                  secondaryTypographyProps={{
                    fontSize: 12
                  }}
                />
              </ListItemButton>
            )
          );
        })}
      </List>
    </>
  );
}
export default PerfilForm;
