import {
  StyledEngineProvider,
  createTheme,
  ThemeProvider
} from '@mui/material/styles';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';
import { orange, green } from '@mui/material/colors';
import AppContext from './AppContext';

const theme = createTheme({
  palette: {
    primary: {
      main: orange[500]
    },
    secondary: {
      main: green[500]
    }
  }
});

function WithindContext(props) {
  const apiProperties = useSelector((state) => state.apiProperties);

  const useRecaptcha =
    apiProperties?.captchaKey && apiProperties?.captchaKey !== 'bypass';
  return (
    <>
      {useRecaptcha && (
        <GoogleReCaptchaProvider
          reCaptchaKey={apiProperties?.captchaKey}
          language="pt-BR"
          useRecaptchaNet="true"
          useEnterprise="false"
          scriptProps={{
            async: false, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: 'head', // optional, default to "head", can be "head" or "body",
            nonce: undefined // optional, default undefined
          }}
          container={{
            // optional to render inside custom element
            element: 'recatchaContainer',
            parameters: {
              badge: '[inline|bottomright|bottomleft]', // optional, default undefined
              theme: 'dark' // optional, default undefined
            }
          }}
        >
          <props.component {...props} />
        </GoogleReCaptchaProvider>
      )}
      {!useRecaptcha && <props.component {...props} />}
    </>
  );
}

const withAppProviders = (Component) => (props) => {
  function WrapperComponent() {
    return (
      <AppContext>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <WithindContext component={Component} />
          </ThemeProvider>
        </StyledEngineProvider>
      </AppContext>
    );
  }

  return WrapperComponent;
};

export default withAppProviders;
