import { createSlice } from '@reduxjs/toolkit';

const apiPropertiesSlice = createSlice({
  name: 'apiProperties',
  initialState: null,
  reducers: {
    setApiProperties: (state, action) => action.payload
  }
});
export const { setApiProperties } = apiPropertiesSlice.actions;
export default apiPropertiesSlice.reducer;
