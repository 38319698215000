export default function ({
  action,
  instituicao,
  empresa,
  fornecedor,
  user,
  perfil,
  score
}) {
  return (
    <form method="post" id="postForm" action={action}>
      <input type="hidden" name="j_instituicao" value={instituicao?.id} />
      <input type="hidden" name="j_fornecedor" value={fornecedor?.id} />
      <input type="hidden" name="j_empresa" value={empresa?.id} />
      <input type="hidden" name="j_usuarioSimulacaoSuporte" value="" />
      <input type="hidden" name="j_forward" value="" />
      <input type="hidden" name="j_modulo" value="SAE" />
      <input type="hidden" name="j_score" value={user?.score} />
      <input type="hidden" name="j_username" value={user?.username} />
      <input type="hidden" name="j_password" value={user?.password} />
      <input type="hidden" name="j_perfil" value={perfil?.id || null} />
      <input type="hidden" name="j_perfilClass" value={perfil?.clazz || null} />
    </form>
  );
}
